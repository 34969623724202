import Swiper from "swiper/js/swiper";

function swiper($selector, $options) {
  return new Swiper($selector, $options);
}

// TEAM SLIDER
swiper(".team__sliderWrap", {
  autoplay: true,
  loop: true,
  speed: 1000,
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev"
  }
});

swiper('.swiper-container', {
  spaceBetween: 0,
  grabCursor: true,
  scrollbar: {
    el: ".swiper-scrollbar",
    draggable: true
  },
  breakpoints: {
    1200: {
      slidesPerView: 5,
      spaceBetween: 10
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 10
    },
    768: {
      slidesPerView: 2,
      spaceBetween: 10
    },
    675: {
      slidesPerView: 1,
      spaceBetween: 20
    }
  }
});
